import loadable from '@loadable/component';

const Column = loadable(() => import('./Column'));
const Row = loadable(() => import('./Row'));
const Container = loadable(() => import('./Container'));
// const BlockWrapper = loadable(() => import('./Block'));
import BlockWrapper from './Block';

const Block = BlockWrapper.Block;
const BlockSecondary = BlockWrapper.BlockSecondary;
const BlockTransparent = BlockWrapper.BlockTransparent;
const BlockBordered = BlockWrapper.BlockBordered;
const BlockFlex = BlockWrapper.BlockFlex;

export { Container, Row, Column, Block, BlockSecondary, BlockTransparent, BlockBordered, BlockFlex };
