import React from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import { withCssUnit } from 'common/components/styleguide/utils';

const S = {};

S.h1 = styled.h1`
  font-family: ${({ theme }) => _.get(theme, 'headline.fontFamily')};
  color: ${({ theme, color }) =>
    _.get(theme, ['colors', color], _.get(theme, 'colors.text'))};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center; //baseline
  align-content: center;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: normal;
  @media (max-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  ${({ theme }) => css`
    ${_.get(theme, 'headline.h1.customStyle', '')}
  `}
`;

const Headline1 = ({ children, color = 'text', ...rest }) => {
  return (
    <S.h1 color={color} {...rest}>
      <span>{children}</span>
    </S.h1>
  );
};

S.h2 = styled.h2`
  font-family: ${({ theme }) => _.get(theme, 'headline.fontFamily')};
  color: ${({ theme, color }) =>
    _.get(theme, ['colors', color], _.get(theme, 'colors.text'))};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center; //baseline
  align-content: center;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: normal;
  @media (max-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  ${({ theme }) => css`
    ${_.get(theme, 'headline.h2.customStyle', '')}
  `}
`;

const Headline2 = ({ children, color = 'text', ...rest }) => {
  return (
    <S.h2 color={color} {...rest}>
      <span>{children}</span>
    </S.h2>
  );
};

S.h3 = styled.h3`
  font-family: ${({ theme }) => _.get(theme, 'headline.fontFamily')};
  color: ${({ theme, color }) =>
    _.get(theme, ['colors', color], _.get(theme, 'colors.text'))};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center; //baseline
  align-content: center;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: normal;
  @media (max-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  ${({ theme }) => css`
    ${_.get(theme, 'headline.h3.customStyle', '')}
  `}
`;

const Headline3 = ({ children, color = 'text', ...rest }) => {
  return (
    <S.h3 color={color} {...rest}>
      <span>{children}</span>
    </S.h3>
  );
};

S.h4 = styled.h4`
  font-family: ${({ theme }) => _.get(theme, 'headline.fontFamily')};
  color: ${({ theme, color }) =>
    _.get(theme, ['colors', color], _.get(theme, 'colors.text'))};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center; //baseline
  align-content: center;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: normal;
  @media (max-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  ${({ theme }) => css`
    ${_.get(theme, 'headline.h4.customStyle', '')}
  `}
`;

const Headline4 = ({ children, color = 'text', ...rest }) => {
  return (
    <S.h4 color={color} {...rest}>
      <span>{children}</span>
    </S.h4>
  );
};

S.h5 = styled.h5`
  font-family: ${({ theme }) => _.get(theme, 'headline.fontFamily')};
  color: ${({ theme, color }) =>
    _.get(theme, ['colors', color], _.get(theme, 'colors.text'))};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center; //baseline
  align-content: center;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: normal;
  @media (max-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  ${({ theme }) => css`
    ${_.get(theme, 'headline.h5.customStyle', '')}
  `}
`;

const Headline5 = ({ children, color = 'text', ...rest }) => {
  return (
    <S.h5 color={color} {...rest}>
      <span>{children}</span>
    </S.h5>
  );
};

S.h6 = styled.h6`
  font-family: ${({ theme }) => _.get(theme, 'headline.fontFamily')};
  color: ${({ theme, color }) =>
    _.get(theme, ['colors', color], _.get(theme, 'colors.text'))};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center; //baseline
  align-content: center;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: normal;
  @media (max-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  ${({ theme }) => css`
    ${_.get(theme, 'headline.h6.customStyle', '')}
  `}
`;

const Headline6 = ({ children, color = 'text', ...rest }) => {
  return (
    <S.h6 color={color} {...rest}>
      <span>{children}</span>
    </S.h6>
  );
};

export { Headline1, Headline2, Headline3, Headline4, Headline5, Headline6 };
