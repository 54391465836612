import React, { Component } from 'react';
import CookieConsent from 'react-cookie-consent';
import { withTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';
import _ from 'lodash';

import { COOKIE_CONSENT } from 'common/constants/SiteConstants';
import { capfl } from 'common/utils';

class Consent extends Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { t, theme } = this.props;
    return (
      <CookieConsent
        location="bottom"
        buttonText={capfl(t('accept'))}
        cookieName={COOKIE_CONSENT}
        expires={150}
        style={{ justifyContent: 'center', alignItems: 'center' }}
        contentStyle={{
          flex: 'auto !important',
          margin: '10px 0',
          textAlign: 'center'
        }}
        buttonStyle={{
          background: _.get(theme, 'colors.secondary'),
          color: _.get(theme, 'colors.primary'),
          height: _.get(theme, 'button.height.normal'),
          padding: _.get(theme, 'button.padding.big'),
          borderRadius: _.get(theme, 'button.borderRadius'),
          margin: '10px 0 10px 10px',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          outline: 'none'
        }}
      >
        {capfl(t('this website uses cookies to enhance the user experience'))}
      </CookieConsent>
    );
  }
}

export default withTranslation()(withTheme(Consent));
