import React from 'react';
import { withTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';
import _ from 'lodash';
import { capfl } from 'common/utils';
import { COOKIE_CONSENT } from 'common/constants/SiteConstants';

import { useWebInstance } from 'common/hooks';

import CookieConsentVariant1 from './CookieConsentVariant1';

const CookieConsent = ({ t, theme }) => {
  const webInstance = useWebInstance();
  const cookieConsentData = React.useMemo(() => ({
    theme: theme,
    headlineText: capfl(t('this website use cookies')),
    contentText: capfl(t('we use cookies and other tracking technologies to improve your browsing experience on this website, to show you personalized content and targeted ads, to analyze website traffic, and to understand where our visitors are coming from.')) + ' ' + capfl(t('by browsing our website, you consent to our use of cookies and other tracking technologies.')),
    buttonText: capfl(t('accept and continue browsing')),
    cookiePolicyLink: _.get(webInstance, 'globalSettings.cookie_policy_page', null),
    data: {
        debug: false,
        name: COOKIE_CONSENT,
        expires: 150,
        location: 'bottom'
    }
  }), []);
  return <CookieConsentVariant1 {...cookieConsentData} />;
};

export default withTranslation()(withTheme(React.memo(CookieConsent)));
