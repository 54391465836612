import React from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';

import { withCssUnit } from 'common/components/styleguide/utils/index';

const StyledBlock = styled.section`
  box-sizing: border-box;
  display: ${({ flex }) => (flex ? 'flex' : 'block')};
  ${({ flexConfig }) => {
    let finalFlex = '';
    finalFlex = finalFlex + ( _.get(flexConfig, 'display', false) ? `display: ${flexConfig.display};` : '');
    finalFlex = finalFlex + ( _.get(flexConfig, 'flexDirection', false) ? `flex-direction: ${flexConfig.flexDirection};` : '');
    finalFlex = finalFlex + ( _.get(flexConfig, 'flexWrap', false) ? `flex-wrap: ${flexConfig.flexWrap};` : '');
    finalFlex = finalFlex + ( _.get(flexConfig, 'flexGrow', false) ? `flex-grow: ${flexConfig.flexGrow};` : '');
    finalFlex = finalFlex + ( _.get(flexConfig, 'flexFlow', false) ? `flex-flow: ${flexConfig.flexFlow};` : '');
    finalFlex = finalFlex + ( _.get(flexConfig, 'justifyContent', false) ? `justify-content: ${flexConfig.justifyContent};` : '');
    finalFlex = finalFlex + ( _.get(flexConfig, 'alignItems', false) ? `align-items: ${flexConfig.alignItems};` : '');
    finalFlex = finalFlex + ( _.get(flexConfig, 'alignContent', false) ? `align-content: ${flexConfig.alignItems};` : '');
    return finalFlex;
  }};
  ${({ theme, borderRadius }) => {
    if (borderRadius === false) {
      return '';
    }
    return `border-radius: ${(_.isString(borderRadius) && borderRadius) ? borderRadius : _.get(theme, 'border.panels.radius', '0px')};`;
  }}
  color: ${({ theme, color }) => _.get(theme, ['colors', color], 'inherit')};
  background-color: ${({ theme, backgroundColor }) => _.get(theme, ['colors', backgroundColor], 'transparent')};
  text-align: ${({ textAlign }) => textAlign};
  padding: ${({ theme, padding, paddingConfig }) => {
    return padding ? padding : ({theme, paddingConfig}) => {
      let finalPadding = '';
      const defaultPadding = withCssUnit(_.get(theme, 'gutterWidth', 0));
      finalPadding = _.get(paddingConfig, 'top', false) ? defaultPadding + ' ' : '0 ';
      finalPadding = finalPadding + (_.get(paddingConfig, 'right', false) ? defaultPadding + ' ' : '0 ');
      finalPadding = finalPadding + (_.get(paddingConfig, 'bottom', false) ? defaultPadding + ' ' : '0 ');
      finalPadding = finalPadding + (_.get(paddingConfig, 'left', false) ? defaultPadding + ' ' : '0 ');
      return finalPadding;
    };
  }};
  margin: ${({ theme, margin, marginConfig }) => {
    return margin ? margin : ({theme, marginConfig}) => {
      let finalMargin = '';
      const defaultMargin = withCssUnit(_.get(theme, 'gutterWidth', 0));
      finalMargin = _.get(marginConfig, 'top', false) ? defaultMargin + ' ' : '0 ';
      finalMargin = finalMargin + (_.get(marginConfig, 'right', false) ? defaultMargin + ' ' : '0 ');
      finalMargin = finalMargin + (_.get(marginConfig, 'bottom', false) ? defaultMargin + ' ' : '0 ');
      finalMargin = finalMargin + (_.get(marginConfig, 'left', false) ? defaultMargin + ' ' : '0 ');
      return finalMargin;
    };
  }};

  ${({ theme, border, borderConfig }) => {
    return border ? `border: ${border};`  : ({theme, borderConfig}) => {
      let finalBorder = '';
      const defaultBorderValue = _.get(theme, 'border.default.width') + ' ' + _.get(theme, 'border.default.style') + ' ' + _.get(theme, 'border.default.color');
      finalBorder = _.get(borderConfig, 'top', false) ? 'border-top: ' + defaultBorderValue + '; ' : '';
      finalBorder = finalBorder + (_.get(borderConfig, 'right', false) ? 'border-right: ' + defaultBorderValue + '; ' : '');
      finalBorder = finalBorder + (_.get(borderConfig, 'bottom', false) ? 'border-bottom: ' + defaultBorderValue + '; ' : '');
      finalBorder = finalBorder + (_.get(borderConfig, 'left', false) ? 'border-left: ' + defaultBorderValue + '; ' : '');
      return finalBorder;
    };
  }}

  ${({ customStyle }) => css`
    ${customStyle}
  `}
`;

const Block = ({
  className,
  children,
  color = 'text',
  backgroundColor = 'background',
  textAlign = 'left',
  padding = false,
  margin = false,
  border = false,
  paddingConfig = {top: true, left: true, right: true, bottom: true},
  marginConfig = {top: true, left: true, right: true, bottom: true},
  borderConfig = {},
  borderRadius = false,
  customStyle,
  flex = false,
  flexConfig = {}
}) => {
  return (
    <StyledBlock
      className={className}
      color={color}
      backgroundColor={backgroundColor}
      textAlign={textAlign}
      padding={padding}
      margin={margin}
      border={border}
      paddingConfig={paddingConfig}
      marginConfig={marginConfig}
      borderConfig={borderConfig}
      borderRadius={borderRadius}
      customStyle={customStyle}
      flex={flex}
      flexConfig={flexConfig}
    >
      {children}
    </StyledBlock>
  );
};

const BlockSecondary = ({
  className,
  children,
  color = 'text',
  backgroundColor = 'backgroundSecondary',
  textAlign = 'left',
  padding = false,
  margin = false,
  border = false,
  paddingConfig = {top: true, left: true, right: true, bottom: true},
  marginConfig = {top: false, left: false, right: false, bottom: true},
  borderConfig = {},
  borderRadius = false,
  customStyle,
  flex = false,
  flexConfig = {}
}) => {
  return (
    <Block
      className={className}
      color={color}
      backgroundColor={backgroundColor}
      textAlign={textAlign}
      padding={padding}
      margin={margin}
      border={border}
      paddingConfig={paddingConfig}
      marginConfig={marginConfig}
      borderConfig={borderConfig}
      borderRadius={borderRadius}
      customStyle={customStyle}
      flex={flex}
      flexConfig={flexConfig}
    >
      {children}
    </Block>
  );
};

const BlockTransparent = ({
  className,
  children,
  color = 'text',
  backgroundColor = 'transparent',
  textAlign = 'left',
  padding = false,
  margin = false,
  border = false,
  paddingConfig = {},
  marginConfig = {top: true},
  borderConfig = {},
  borderRadius = false,
  customStyle,
  flex = false,
  flexConfig = {}
}) => {
  return (
    <Block
      className={className}
      color={color}
      backgroundColor={backgroundColor}
      textAlign={textAlign}
      padding={padding}
      margin={margin}
      border={border}
      paddingConfig={paddingConfig}
      marginConfig={marginConfig}
      borderConfig={borderConfig}
      borderRadius={borderRadius}
      customStyle={customStyle}
      flex={flex}
      flexConfig={flexConfig}
    >
      {children}
    </Block>
  );
};

const BlockBordered = ({
  className,
  children,
  color = 'text',
  backgroundColor = 'transparent',
  textAlign = 'left',
  padding = false,
  margin = false,
  border = false,
  paddingConfig = {top: true, left: true, right: true, bottom: true},
  marginConfig = {top: true},
  borderConfig = {top: true, left: true, right: true, bottom: true},
  borderRadius = false,
  customStyle,
  flex = false,
  flexConfig = {}
}) => {
  return (
    <Block
      className={className}
      color={color}
      backgroundColor={backgroundColor}
      textAlign={textAlign}
      padding={padding}
      margin={margin}
      border={border}
      paddingConfig={paddingConfig}
      marginConfig={marginConfig}
      borderConfig={borderConfig}
      borderRadius={borderRadius}
      customStyle={customStyle}
      flex={flex}
      flexConfig={flexConfig}
    >
      {children}
    </Block>
  );
};

const BlockFlex = ({
  className,
  children,
  color = 'text',
  backgroundColor = 'transparent',
  textAlign = 'left',
  padding = false,
  margin = false,
  border = false,
  paddingConfig = {},
  marginConfig = {},
  borderConfig = {},
  borderRadius = false,
  customStyle,
  flex = true,
  flexConfig = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}) => {
  return (
    <Block
      className={className}
      color={color}
      backgroundColor={backgroundColor}
      textAlign={textAlign}
      padding={padding}
      margin={margin}
      border={border}
      paddingConfig={paddingConfig}
      marginConfig={marginConfig}
      borderConfig={borderConfig}
      borderRadius={borderRadius}
      customStyle={customStyle}
      flex={flex}
      flexConfig={flexConfig}
    >
      {children}
    </Block>
  );
};

const BlockWrapper = {
  Block: Block,
  BlockSecondary: BlockSecondary,
  BlockTransparent: BlockTransparent,
  BlockBordered: BlockBordered,
  BlockFlex: BlockFlex
}
export default BlockWrapper;
export { Block, BlockSecondary, BlockTransparent, BlockBordered, BlockFlex };
