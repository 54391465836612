import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

import { capfl } from 'common/utils';

import { Column, BlockFlex, BlockTransparent } from 'common/components/styleguide/grid';
import { Headline4 } from 'common/components/styleguide/Headline';

import loadable from '@loadable/component';
const Emoji = loadable(() => import('common/components/styleguide/Emoji'), {ssr: false});

const CookieConsentVariant1 = ({
  theme,
  headlineText,
  contentText,
  buttonText,
  cookiePolicyLink,
  data
}) => {
  const [t] = useTranslation();
  return (
    <CookieConsent
      location={data.location}
      buttonText={buttonText}
      cookieName={data.name}
      debug={data.debug}
      style={get(theme, 'cookieConsent.body')}
      expires={data.expires}
      buttonStyle={{
        marginTop: '0',
        background: get(theme, 'colors.primary'),
        color: get(theme, 'colors.white'),
        textDecoration: get(theme, 'button.textDecoration'),
        padding: get(theme, 'button.padding.normal'),
        paddingTop: '10px',
        paddingBottom: '10px',
        fontSize: get(theme, 'button.fontSize.normal'),
        borderRadius: get(theme, 'button.borderRadius')
      }}
    >
      <BlockFlex>
        <section>
          <Headline4>{headlineText}</Headline4>
          {contentText}
          {cookiePolicyLink ? (
            <BlockTransparent margin="10px 0 0 0" padding="0">
              <a href={cookiePolicyLink}>{capfl(t('more about cookies'))}</a>
            </BlockTransparent>
          ) : (
            ''
          )}
        </section>
        <Column visible={{ xs: false, sm: false, md: true, lg: true }}>
          <Emoji
            label={'cookie'}
            style={{ fontSize: '6.5em', marginLeft: '15px' }}
          >
            🍪
          </Emoji>
        </Column>
      </BlockFlex>
    </CookieConsent>
  );
};

export default CookieConsentVariant1;
